<template>
  <v-card
    flat
    class="mt-5"
  >
      <v-card-text>
    <div id="app">
        <v-data-table
    :headers="headers"
    :items="list"
    sort-by="code"
    class="elevation-1"
  >
     <template v-slot:top>
      <v-toolbar
        flat
      >
              <v-toolbar-title>LISTE DES UTILISATEURS</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
        
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      color="primary"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
        </template>
        </v-snackbar>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
             @click="nouveau()" 
            >
              Nouveau
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                  >
           
                 <v-text-field
                      v-model="nom"
                      label="Nom et Prénom *"
                      dense
                      outlined
                     
                    ></v-text-field>
                     <v-text-field
                      v-model="email"
                      label="email "
                      dense
                      outlined
                     
                    ></v-text-field>
                     <v-text-field
                      v-model="password"
                      label="password"
                      dense
                      outlined
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      @click:append="show1 = !show1"
                     
                    ></v-text-field>
                     <v-text-field
                      v-model="tel"
                      label="Téléphone "
                      dense
                      outlined
                     
                    ></v-text-field>
                  
                   
                </v-form>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialog=false"
              >
                Fermer
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete=false">Annuller</v-btn>
              <v-btn color="blue darken-1" text @click="supdata()">Confirmer</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
   
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="Actulisation"
      >
        Actualiser la liste
      </v-btn>
    </template>

     <template v-slot:[`item.statut`]="{ item }">
      <v-chip
        :color="getColor(item.statut)"
        dark
      >
        {{ getstatut(item.statut) }}
      </v-chip>
    </template>
  </v-data-table>
        

    </div>
  
    </v-card-text>
  </v-card>
</template>

<script>
import api from './../../../serviceApi/apiService'

export default {
  data:() =>({
           multiLine: true,
      snackbar: false,
      snackbars:false,
      text: `message`,
      textsnackbar:'messages',
      dialog: false,
      dialogDelete: false,
      textetat:false,
          headers: [
        { text: 'ID', value: 'id' ,divider: true,width: '30px'},
        { text: 'Nom et Prénom', value: 'nom' ,divider: true,width: '350'},
        { text: 'Email', value: 'email' ,divider: true,width: '350px'},
        { text: 'Téléphone', value: 'tel' ,divider: true,width: '200px'},
        { text: 'Etat', value: 'statut' ,divider: true,width: '50px'},
        { text: 'Validation', value: 'valide' ,divider: true,width: '20px'},
        { text: 'Actions', value: 'actions', sortable: false ,divider: true,width: '80px'},
      ],
       show1: false,
        list:[],
         nom:'',
         id:'0',
         email:'',
         password:'',
         tel:'',
         ifu:'',
         valider: '',
         statut:'',
         textetat:false,
         formTitle:"Formulaire d'enregistrement",

  }),
     methods: {
          getColor (itemstatut) {
        if (itemstatut == 0) return 'red'
        else if (itemstatut == 1) return 'green'
        else return 'green'
      },
       getstatut (itemstatut) {
        if (itemstatut == 0) return 'Desactiver'
        else if (itemstatut ==1) return 'Activer'
        else return 'Desactiver'
      },
       //=====Afficher tout=======
       
      readAll: async function() {
       const data = await api.readAll('auth/all-user');
      this.list = data.list;
      },
    
   

   createUpdatedata: async function() {
      
      let fd=new FormData();
      //this.id_odds=this.selectodd;
      fd.append('nom',this.nom);
      if(this.password==null||this.password==''){

      }else{
      fd.append('password',this.password);
     
      }
      fd.append('email',this.email);
      fd.append('tel',this.tel);
      
   if(this.textetat){
      console.log(this.id);
     const res=await api.createUpdatedata('auth/register-update/'+this.id,fd);
     console.log(res);
      this.snackbar=true;  
       this.dialog=false;  
    }else{
     const res=await api.createUpdatedata('auth/register',fd);    
       this.text=res.message;
        this.snackbar=true; 
       
       
      }
        
        this.clear();       
        this.readAll();
      
    },
     delete: async function() {
       console.log(this.id);
     let fd=new FormData();
      fd.append('id',this.id);
       const res=await api.createUpdatedata('auth/delete',fd);   
      
     this.readAll();
    this.dialogDelete=false;
    },
    
    supdata(){
      this.delete();
      
    }, 
    save(){
      this.createUpdatedata();
      
    },
    nouveau(){
     this.clear();
     this.dialog=true;
    },
    editItem(item){
         this.nom=item.nom;
         this.id=item.id;
         this.email=item.email;
         this.tel=item.tel;
         this.textetat=true;
      this.dialog=true;
    },

    deleteItem(item){
      console.log(item.id);
       this.id=item.id;
       this.dialogDelete=true;
      
    },

    clear(){
         
         this.id='0';
         this.email='';
         this.nom='';
         this.password='';
         this.tel='';
        this.textetat=false;
         
          
    }

    },
   
   mounted() {
    this.readAll();

  },


}
</script>


<style lang="scss" scoped>
.app-content-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    height: calc(100vh - 200px);

    .app-overlay {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
        display: none;
        z-index: 2;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            &.open {
                display: block;
                transition: all 0.3s ease-in;
            }
        }
    }
    .app-sidebar {
        width: 280px;
        position: relative;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            position: absolute;
            left: -340px;
            z-index: 5;
            height: calc(100vh - 120px);
            transition: all 0.3s ease-in;
            &.open {
                left: 0;
            }
        }
        .app-sidebar-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .app-sidebar-body {
            // overflow-y: scroll;
            // height: 100%;
            padding-top: 3.375rem;
            width: 280px;
        }
    }

    .app-content {
        width: calc(100% - 280px);
        @media only screen and (max-width: 959px) {
            width: 100%;
        }
        // margin-left: 30px;
        .app-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .app-body {
            flex: 1 1 auto;
            padding-top: 3.375rem;
            // overflow-y: scroll;
            height: calc(100% - 130px);
        }
        .app-footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 130px;
        }
    }
}
.app-icon {
    display: none;
    @media only screen and (max-width: 959px) {
        display: block;
    }
}
.eg-filemanager {
    background-color: #fff;
}
.filemanager-content {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
}

.apps-wrapper {
    &.--filemanager {
        .nested-sidebar-toggle {
            @media (min-width: 959px) {
                display: none;
            }
        }
    }
}
</style>

